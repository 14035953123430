<template>
  <div>
    <div class="row">
      <div class="col">
        <div
          v-if="message"
          class="alert alert-success alert-dismissible fade show">
          {{ message }}
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="message = ''">
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-9 col-lg-6">
        <h2>Load group</h2>
        <div class="input-group">
          <select id="load" class="form-select" v-model="selectedGroupName">
            <option v-for="save in getSortedSavedGroups" :value="save.name" :key="save.name">{{ save.name }}</option>
          </select>
          <button class="btn btn-primary" type="button" @click="loadSelectedGroup" :disabled="getLoadGroupButtonDisabled">Load</button>
        </div>
      </div>
    </div>

    <div class="achievement-section">
      <div class="row">
        <div class="col-md-6">
          <h2>Load achievements</h2>
          <div class="input-group">
            <select id="loadAchievementGroup" class="form-select" v-model="selectedAchievementGroupName">
              <option v-for="save in getSortedSavedAchievementGroups" :value="save.name" :key="save.name">{{ save.name }}</option>
            </select>
            <button class="btn btn-primary" type="button" @click="loadSavedAchievementGroup">Load</button>
            <button class="btn btn-secondary" type="button" @click="removeSavedAchievementGroup">Remove</button>
          </div>
        </div>
        <div class="col-md-6">
          <h2>Save achievements</h2>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="New achievement set name" v-model.trim="newAchievementGroupName">
            <button class="btn btn-primary" type="button" @click="saveNewAchievementGroup">Save</button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-9">
          <h2>Find achievements</h2>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Achievement name or ID" v-model.trim="searchAchievementText">
            <button class="btn btn-primary" type="button" @click="searchAchievements" :disabled="getSearchAchievementsButtonDisabled">Search</button>
          </div>
          <div v-if="foundAchievements.length > 0" id="foundAchievements">
            <table>
              <tr v-for="foundAchievement in foundAchievements" v-bind:key="foundAchievement.id">
                <td>{{ foundAchievement.name }}&nbsp;&nbsp;[{{ foundAchievement.id }}]</td>
                <td>
                  <button class="btn btn-primary btn-sm btn-simple" type="button" :disabled="achievementIsSelected(foundAchievement)" @click="addAchievement(foundAchievement)">+</button>
                  <button class="btn btn-primary btn-sm btn-simple" type="button" :disabled="!achievementIsSelected(foundAchievement)" @click="removeAchievement(foundAchievement)">-</button>
                </td>
              </tr>
            </table>
            <button class="btn btn-primary btn-sm" type="button" @click="foundAchievements = []">Done</button>
          </div>
        </div>
      </div>

      <!-- Sort -->
      <div class="row">
        <div class="col-md-6">
          <h3>Sort by</h3>
          <select id="sortAchievements" class="form-select" v-model.trim="sortAchievements">
            <option value="completion">Completion</option>
            <option value="name">Name</option>
          </select>
        </div>
      </div>

      <!-- Filter -->
      <div class="row">
        <div class="col-md-6">
          <h3>Filter by player or missing achievement step</h3>
          <input type="text" class="form-control" v-model.trim="filterAchievementText">
        </div>
      </div>

      <div class="achievements">
        <div v-for="achievement in getCharAchievements" v-bind:key="achievement.id">
          <div class="row">
            <div class="col">
              <h3>
                {{ achievement.name }}
                <button class="btn btn-secondary btn-sm btn-simple" type="button" @click="(event) => copyAchievementLink(achievement, event)"><font-awesome-icon :icon="['fas', 'link']" /></button>
                <button class="btn btn-secondary btn-sm btn-simple" type="button" @click="removeAchievement(achievement)"><font-awesome-icon :icon="['fas', 'times']" /></button>
                <button class="btn btn-secondary btn-sm btn-simple" type="button" @click="moveAchievementUp(achievement)"><font-awesome-icon :icon="['fas', 'angle-up']" /></button>
                <button class="btn btn-secondary btn-sm btn-simple" type="button" @click="moveAchievementDown(achievement)"><font-awesome-icon :icon="['fas', 'angle-down']" /></button>
              </h3>
              <div class="achievement-description">
                <div class="achievement-description-text" v-html="achievement.description"></div>
              </div>
              <div v-if="achievement.chars.length > 0">
                <div v-for="char in achievement.chars" v-bind:key="char.name" class="char-achievement">
                  {{ char.name }}
                  <font-awesome-icon v-if="char.completed" class="char-achievement-success" :icon="['fas', 'check']" />
                  <font-awesome-icon v-if="!char.completed && char.stepCount == 1" class="char-achievement-fail" :icon="['fas', 'times']" />
                  <div v-if="!char.completed && char.stepCount > 1" class="char-achievement-count achievement-tooltip">
                    {{ char.completedCount }}/{{ achievement.stepCount }}
                    <span class="achievement-tooltip-text" v-html="char.tooltip"></span>
                  </div>
                </div>
              </div>
              <div v-else>
                <div v-if="isLoadingGroup">Loading...</div>
                <div v-else>No group selected, or no filter matches.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="collection-section">
      <div class="row">
        <div class="col-md-9">
          <h2>Collections</h2>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Collection name or ID" v-model.trim="searchCollectionText">
            <button class="btn btn-primary" type="button" @click="searchCollections" :disabled="getSearchCollectionsButtonDisabled">Search</button>
          </div>
          <div v-if="foundCollections.length > 0" id="foundCollections">
            <table>
              <tr v-for="foundCollection in foundCollections" v-bind:key="foundCollection.id">
                <td>{{ foundCollection.name }}&nbsp;&nbsp;[{{ foundCollection.id }}]</td>
                <td>
                  <button class="btn btn-primary btn-sm btn-simple" type="button" :disabled="collectionIsSelected(foundCollection)" @click="addCollection(foundCollection)">+</button>
                  <button class="btn btn-primary btn-sm btn-simple" type="button" :disabled="!collectionIsSelected(foundCollection)" @click="removeCollection(foundCollection)">-</button>
                </td>
              </tr>
            </table>
            <button class="btn btn-primary btn-sm" type="button" @click="foundCollections = []">Done</button>
          </div>
        </div>
      </div>

      <!-- Sort -->
      <div class="row">
        <div class="col-md-6">
          <h3>Sort by</h3>
          <select id="sortCollections" class="form-select" v-model.trim="sortCollections">
            <option value="completion">Completion</option>
            <option value="name">Name</option>
          </select>
        </div>
      </div>

      <!-- Filter -->
      <div class="row">
        <div class="col-md-6">
          <h3>Filter by player or missing collection item</h3>
          <input type="text" class="form-control" v-model.trim="filterCollectionText">
        </div>
      </div>

      <div class="collections">
        <div v-for="collection in getCharCollections" v-bind:key="collection.id">
          <div class="row">
            <div class="col">
              <h3>
                {{ collection.name }}
                <button class="btn btn-secondary btn-sm btn-simple" type="button" @click="removeCollection(collection)"><font-awesome-icon :icon="['fas', 'times']" /></button>
                <button class="btn btn-secondary btn-sm btn-simple" type="button" @click="moveCollectionUp(collection)"><font-awesome-icon :icon="['fas', 'angle-up']" /></button>
                <button class="btn btn-secondary btn-sm btn-simple" type="button" @click="moveCollectionDown(collection)"><font-awesome-icon :icon="['fas', 'angle-down']" /></button>
              </h3>
              <div v-if="collection.chars.length > 0">
                <div v-for="char in collection.chars" v-bind:key="char.name" class="char-collection">
                  {{ char.name }}
                  <font-awesome-icon v-if="char.completed" class="char-collection-success" :icon="['fas', 'check']" />
                  <font-awesome-icon v-if="!char.completed && char.itemCount == 1" class="char-collection-fail" :icon="['fas', 'times']" />
                  <div v-if="!char.completed && char.itemCount > 1" class="char-collection-count collection-tooltip">
                    {{ char.completedCount }}/{{ collection.itemCount }}
                    <span class="collection-tooltip-text" v-html="char.tooltip"></span>
                  </div>
                </div>
              </div>
              <div v-else>
                <div v-if="isLoadingGroup">Loading...</div>
                <div v-else>No group selected, or no filter matches.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="gear-section">
      <div class="row">
        <div class="col-md-9">
          <h2>Gear</h2>
        </div>

        <!-- Sort -->
        <div class="row">
          <div class="col-md-6">
            <h3>Sort by</h3>
            <select id="sortGear" class="form-select" v-model.trim="sortGear">
              <option value="name">Name</option>
              <option value="resolve">Resolve</option>
              <option value="cboc">CBOC</option>
              <option value="purpleAdorns">Purple Slots</option>
              <option value="badAdorns">Adorn Issues</option>
              <option value="rending">Rending</option>
              <option value="plumage">Plume</option>
              <option value="gold">Gold Faction</option>
              <option value="iron">Iron Faction</option>
            </select>
          </div>
        </div>
      </div>

      <div class="gears">
        <div>
          <div class="row">
            <div class="col">
              <div v-if="getCharGear.chars?.length > 0">
                <table class="report">
                  <tr>
                    <th>Name</th>
                    <th>Resolve</th>
                    <th>CBOC</th>
                    <th>Purple Slots</th>
                    <th>Adorn Issues</th>
                    <th>Rending</th>
                    <th>Plume</th>
                    <th>Gold Faction</th>
                    <th>Iron Faction</th>
                  </tr>
                  <tr v-for="char in getCharGear.chars" v-bind:key="char.name">
                    <td><a :href="getCharLink(char)" target="_blank">{{ char.name }}</a></td>
                    <td>{{ char.resolve }}</td>
                    <td>{{ char.cbocCount }}</td>
                    <td>{{ char.purpleAdornCount }}</td>
                    <td>{{ char.emptyAdornCount + char.oldAdornCount }}</td>
                    <td>{{ char.rendingAdorns }}</td>
                    <td>{{ char.plumage }}</td>
                    <td>{{ char.gold }}</td>
                    <td>{{ char.iron }}</td>
                  </tr>
                </table>
              </div>
              <div v-else>
                <div v-if="isLoadingGroup">Loading...</div>
                <div v-else>No group selected.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { publicPath } from "../../vue.config";
import { getPlayerStatsByIdAsync, listAchievementsAsync, getAchievementAsync, listCollectionsAsync, getCollectionAsync } from "@/util/eqApi";
import { getGroups, loadGroup, addOrReplaceGroup, getAchievementGroups, loadAchievementGroup, addOrReplaceAchievementGroup, removeAchievementGroup, addToLocalStorage, getFromLocalStorage } from "@/util/storage";

export default {
  name: "Report",
  components: {
  },
  data: function () {
    return {
      optsAchievementIds: [],
      optsCollectionIds: [],
      optsGroupName: "",
      chars: [],
      achievements: [],
      collections: [],
      message: "",
      savedGroups: [],
      selectedGroupName: "",
      searchAchievementText: "",
      searchCollectionText: "",
      isLoadingGroup: false,
      isSearchingAchievements: false,
      isSearchingCollections: false,
      foundAchievements: [],
      foundCollections: [],
      sortAchievements: "completion",
      sortCollections: "completion",
      savedAchievementGroups: [],
      selectedAchievementGroupName: "",
      newAchievementGroupName: "",
      sortGear: "name",
      filterAchievementText: '',
      filterCollectionText: ''
    };
  },
  mounted: async function () {
    this.parseOpts();
    this.loadSavedGroups();
    this.loadSavedAchievementGroups();

    if (this.optsAchievementIds.length > 0) {
      await this.loadAchievements(this.optsAchievementIds);
    }
    if (this.achievements.length < 1) {
      this.loadAchievementsFromStorage();
    }

    if (this.optsCollectionIds.length > 0) {
      await this.loadCollections(this.optsCollectionIds);
    }
    if (this.collections.length < 1) {
      this.loadCollectionsFromStorage();
    }

    if (this.optsGroupName) {
      this.selectedGroupName = this.optsGroupName;
      await this.loadSelectedGroup();
    }
  },
  watch: {
    $route: async function () {
      this.loadAchievementsFromStorage();
      this.loadCollectionsFromStorage();
      this.loadSavedGroups();
      this.loadSavedAchievementGroups();
    },
  },
  computed: {
    getSortedSavedGroups() {
      const saves = [...this.savedGroups];
      saves.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      return saves;
    },
    getLoadGroupButtonDisabled() {
      return this.isLoadingGroup;
    },
    getSearchAchievementsButtonDisabled() {
      return this.isSearchingAchievements;
    },
    getSearchCollectionsButtonDisabled() {
      return this.isSearchingCollections;
    },
    getCharAchievements() {
      const charAchievements = [];
      const filterText = this.filterAchievementText.trim();
      const filterRegex = filterText == "" ? null : new RegExp(filterText, 'i');

      for (const achievement of this.achievements) {
        const curAchievement = {
          ...achievement,
          chars: []
        };

        let achievementId = achievement.id;
        curAchievement.gameLink = `\\aACH ${achievementId}:${achievement.name}\\/a`;
        charAchievements.push(curAchievement);

        for (const char of this.chars) {
          let includePlayer = false;

          // Check if we should filter this player out
          if (filterRegex && char.name.match(filterRegex)) {
            includePlayer = true;
          }

          const charAchievement = this.findCharAchievement(char, achievement.id);
          const charAchievementResult = {
            name: char.name,
            completed: false,
            completedCount: 0,
            stepCount: curAchievement.stepCount,
            tooltip: ""
          }

          let allDescs = '';
          let includeAchieve = false;
          if (charAchievement != null) {
            charAchievementResult.completed = charAchievement.completed_timestamp != 0;
            if (!charAchievementResult.completed) {
              const eventList = charAchievement.event_list ?? [];
              for (let i = 0; i < eventList.length; i++) {
                charAchievementResult.completedCount += eventList[i].count ?? 0;
                if (i < (curAchievement.steps ?? []).length && eventList[i].count < curAchievement.steps[i].quota) {
                  charAchievementResult.tooltip += `<span class="step-needed">${curAchievement.steps[i].desc}</span>`;
                  allDescs += curAchievement.steps[i].desc;
                }
              }
            }
            else {
              charAchievementResult.completedCount = achievement.stepCount;
            }
          }
          else {
            if (curAchievement.steps) {
              for (const step of curAchievement.steps) {
                charAchievementResult.tooltip += `<span class="step-needed">${step.desc}</span>`;
                allDescs += step.desc;
              }
            }
          }
          if (filterRegex && allDescs && allDescs.match(filterRegex))
          {
            includeAchieve = true;
          }

          if (!filterRegex || (includePlayer || includeAchieve)) {
            curAchievement.chars.push(charAchievementResult);
          }
        }

        switch (this.sortAchievements) {
          case "name":
            curAchievement.chars.sort((x, y) => {
              return x.name.localeCompare(y.name);
            });
            break;
          case "completion":
            curAchievement.chars.sort((x, y) => {
              if (y.completedCount == x.completedCount) {
                return x.name.localeCompare(y.name);
              }
              return y.completedCount > x.completedCount ? -1 : 1;
            });
            break;
        }
      }

      return charAchievements;
    },
    getCharCollections() {
      const charCollections = [];
      const filterText = this.filterCollectionText.trim();
      const filterRegex = filterText == "" ? null : new RegExp(filterText, 'i');

      for (const collection of this.collections) {
        const curCollection = {
          ...collection,
          chars: []
        };

        charCollections.push(curCollection);

        for (const char of this.chars) {
          let includePlayer = false;

          // Check if we should filter this player out
          if (filterRegex && char.name.match(filterRegex)) {
            includePlayer = true;
          }

          const charCollection = this.findCharCollection(char, collection.id);
          const charCollectionResult = {
            name: char.name,
            completed: false,
            completedCount: 0,
            itemCount: curCollection.itemCount,
            tooltip: ""
          }

          let allDescs = '';
          let includeCollection = false;
          if (charCollection != null) {
            charCollectionResult.completed = charCollection.collectedIds.length == curCollection.itemCount;
            if (!charCollectionResult.completed) {
              charCollectionResult.completedCount = charCollection.collectedIds.length;
              for (const item of curCollection.items ?? []) {
                if (!charCollection.collectedIds.includes(item.id)) {
                  charCollectionResult.tooltip += `<span class="item-needed">${item.name}</span>`;
                  allDescs += item.name;
                }
              }
            }
            else {
              charCollectionResult.completedCount = curCollection.itemCount;
            }
          }
          else {
            for (const item of curCollection.items ?? []) {
              charCollectionResult.tooltip += `<span class="item-needed">${item.name}</span>`;
              allDescs += item.name;
            }
          }
          if (filterRegex && allDescs && allDescs.match(filterRegex))
          {
            includeCollection = true;
          }

          if (!filterRegex || (includePlayer || includeCollection)) {
            curCollection.chars.push(charCollectionResult);
          }
        }

        switch (this.sortCollections) {
          case "name":
            curCollection.chars.sort((x, y) => {
              return x.name.localeCompare(y.name);
            });
            break;
          case "completion":
            curCollection.chars.sort((x, y) => {
              if (y.completedCount == x.completedCount) {
                return x.name.localeCompare(y.name);
              }
              return y.completedCount > x.completedCount ? -1 : 1;
            });
            break;
        }
      }

      return charCollections;
    },
    getCharGear() {
      const charGears = {
        chars: []
      };
      for (const char of this.chars) {
        const charGear = {
          name: char.name,
          server: char.server,
          resolve: char.resolve?.total ?? 0,
          cbocCount: 0,
          emptyAdornCount: char.checkup.emptyAdorns,
          oldAdornCount: char.checkup.oldAdorns,
          purpleAdornCount: 0,
          rendingAdorns: char.checkup.rendingAdorns,
          plumage: char.checkup.plumage,
          gold: char.factions.bozGold,
          iron: char.factions.bozIron,
        }
        for (const prop in char.gear) {
          if (char.gear[prop].cboc && !char.gear[prop].isWeapon) {
            charGear.cbocCount++;
          }
          charGear.purpleAdornCount += char.gear[prop].adorns.purple.count;
        }
        charGears.chars.push(charGear);
      }

      switch (this.sortGear) {
        case "name":
          charGears.chars.sort((x, y) => {
            return x.name.localeCompare(y.name);
          });
          break;
        case "resolve":
          charGears.chars.sort((x, y) => {
            return y.resolve > x.resolve ? -1 : 1;
          });
          break;
        case "cboc":
          charGears.chars.sort((x, y) => {
            if (y.cbocCount == x.cbocCount) {
              return x.name.localeCompare(y.name);
            }
            return y.cbocCount > x.cbocCount ? -1 : 1;
          });
          break;
        case "badAdorns":
          charGears.chars.sort((x, y) => {
            if ((y.emptyAdornCount + y.oldAdornCount) == (x.emptyAdornCount + x.oldAdornCount)) {
              return x.name.localeCompare(y.name);
            }
            return (y.emptyAdornCount + y.oldAdornCount) > (x.emptyAdornCount + x.oldAdornCount) ? -1 : 1;
          });
          break;
        case "purpleAdorns":
          charGears.chars.sort((x, y) => {
            if (y.purpleAdornCount == x.purpleAdornCount) {
              return x.name.localeCompare(y.name);
            }
            return y.purpleAdornCount > x.purpleAdornCount ? -1 : 1;
          });
          break;
        case "rending":
          charGears.chars.sort((x, y) => {
            if (x.rendingAdorns.length == y.rendingAdorns.length) {
              return x.rendingAdorns.localeCompare(y.rendingAdorns);
            }
            return x.rendingAdorns.length > y.rendingAdorns.length ? 1 : -1;
          });
          break;
        case "plumage":
          charGears.chars.sort((x, y) => {
            if (y.plumage == x.plumage) {
              return x.name.localeCompare(y.name);
            }
            return x.plumage.localeCompare(y.plumage);
          });
          break;
        case "gold":
          charGears.chars.sort((x, y) => {
            if (y.gold == x.gold) {
              return x.name.localeCompare(y.name);
            }
            return y.gold > x.gold ? -1 : 1;
          });
          break;
        case "iron":
          charGears.chars.sort((x, y) => {
            if (y.iron == x.iron) {
              return x.name.localeCompare(y.name);
            }
            return y.iron > x.iron ? -1 : 1;
          });
          break;
      }

      return charGears;
    },
    getSortedSavedAchievementGroups() {
      const saves = [...this.savedAchievementGroups];
      saves.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      return saves;
    }
  },
  methods: {
    loadAchievementsFromStorage() {
      this.achievements.splice(0, this.achievements.length);
      this.achievements.push(...(JSON.parse(getFromLocalStorage('app.working.achievements') ?? '[]')));
    },
    loadCollectionsFromStorage() {
      this.collections.splice(0, this.collections.length);
      this.collections.push(...(JSON.parse(getFromLocalStorage('app.working.collections') ?? '[]')));
    },
    saveAchievementsToStorage() {
      addToLocalStorage('app.working.achievements', JSON.stringify(this.achievements));
    },
    saveCollectionsToStorage() {
      addToLocalStorage('app.working.collections', JSON.stringify(this.collections));
    },
    parseOpts: function() {
      const query = this.$route.query;
      if (query) {
        if (query.opts) {
          const opts = JSON.parse(atob(query.opts));

          this.optsGroupName = "";
          if (opts.groupName) {
            addOrReplaceGroup(opts.groupName, opts.chars ?? [])
            this.optsGroupName = opts.groupName;
          }

          this.optsAchievementIds.splice(0, this.optsAchievementIds.count);
          if (opts.achievements && opts.achievements.length > 0) {
            this.optsAchievementIds.push(...opts.achievements);
          }

          this.optsCollectionIds.splice(0, this.optsCollectionIds.count);
          if (opts.collections && opts.collections.length > 0) {
            this.optsCollectionIds.push(...opts.collections);
          }
        }
      }
    },
    loadPlayers: async function (playerIds) {
      if (playerIds.length > 0) {
        this.apiSearch();
        this.message = `Loading ${playerIds.length} character${playerIds.length == 1 ? '' : 's'}... (this can take a while)`;
        var loadingChars = [];
        for (const playerId of playerIds) {
          loadingChars.push(getPlayerStatsByIdAsync(playerId));
        }

        let loadedChars = [];
        let failedChars = [];
        let values = await Promise.allSettled(loadingChars);
        values.forEach(value => {
          if (value.status === 'fulfilled') {
            loadedChars.push(value.value);
          }
          else {
            if (value.reason === 'service_unavailable') {
              this.apiError();
            }
            failedChars.push(value.value);
          }
        });
        loadedChars.sort((x, y) => {
          return x.name.localeCompare(y.name);
        });
        this.chars = loadedChars;

        if (failedChars.length > 0) {
          this.message = `Failed to load ${failedChars.length} of ${playerIds.length} characters`;
        }
        else {
          this.message = "";
        }

        this.updateTitle();
        this.updateLink();

      } else {
        this.message = "";
      }
    },
    loadAchievements: async function (achievementIds) {
      if (achievementIds.length > 0) {
        this.message = `Loading ${achievementIds.length} achievement${achievementIds.length == 1 ? '' : 's'}... (this can take a while)`;
        var loadingAchievements = [];
        for (const achievementId of achievementIds) {
          loadingAchievements.push(getAchievementAsync(achievementId));
        }

        let loadedAchievements = [];
        let failedAchievements = [];
        let values = await Promise.allSettled(loadingAchievements);
        values.forEach(value => {
          if (value.status === 'fulfilled') {
            loadedAchievements.push(value.value);
          }
          else {
            failedAchievements.push(value.value);
          }
        });
        this.achievements = loadedAchievements;
        if (failedAchievements.length > 0) {
          this.message = `Failed to load ${failedAchievements.length} of ${achievementIds.length} achievements`;
          failedAchievements.forEach((failedAchievement) => {
            console.error('Failed to load achievement', failedAchievement);
          });
        }
        else {
          this.message = "";
        }

        this.updateTitle();
        this.updateLink();

      } else {
        this.message = "";
      }
    },
    loadCollections: async function (collectionIds) {
      if (collectionIds.length > 0) {
        this.message = `Loading ${collectionIds.length} collection${collectionIds.length == 1 ? '' : 's'}... (this can take a while)`;
        var loadingCollections = [];
        for (const collectionId of collectionIds) {
          loadingCollections.push(getCollectionAsync(collectionId));
        }

        let loadedCollections = [];
        let failedCollections = [];
        let values = await Promise.allSettled(loadingCollections);
        values.forEach(value => {
          if (value.status === 'fulfilled') {
            loadedCollections.push(value.value);
          }
          else {
            failedCollections.push(value.value);
          }
        });
        this.collections = loadedCollections;
        if (failedCollections.length > 0) {
          this.message = `Failed to load ${failedCollections.length} of ${collectionIds.length} collections`;
          failedCollections.forEach((failedCollection) => {
            console.error('Failed to load collection', failedCollection);
          });
        }
        else {
          this.message = "";
        }

        this.updateTitle();
        this.updateLink();

      } else {
        this.message = "";
      }
    },
    apiError: function () {
      this.$emit("apiError");
    },
    apiSearch: function () {
      this.$emit("apiSearch");
    },
    getGroupCharIds() {
      return this.chars.map(char => { return char.id });
    },
    updateTitle: function (message) {
      if (!message) {
        message = `Report for ${this.selectedGroupName ?? "Unknown"} (${this.chars.length} chars)`;
      }
      document.title = `${message} | eq2cmp`;
    },
    updateLink: function () {
      const opts = {
        chars: this.chars.map(x => x.id),
        groupName: this.selectedGroupName ?? "",
        achievements: this.achievements.map(x => x.id),
        collections: this.collections.map(x => x.id),
      };
      const optsStr = btoa(JSON.stringify(opts));
      this.$emit("newLink", `${window.location.origin}${publicPath}report?opts=${optsStr}`);
    },
    async loadSelectedGroup() {
      this.isLoadingGroup = true;

      if (this.selectedGroupName) {
        const group = loadGroup(this.selectedGroupName);
        if (group) {
          const playerIds = group.ids || [];
          await this.loadPlayers(playerIds);

          this.updateTitle();
          this.updateLink();
        }
      }
      this.isLoadingGroup = false;
    },
    loadSavedGroups() {
      this.savedGroups.splice(0, this.savedGroups.length);
      this.savedGroups.push(...getGroups());
    },
    searchAchievements: async function () {
      this.foundAchievements = [];
      this.isSearchingAchievements = true;
      this.apiSearch();
      try {
        const achievementList = await listAchievementsAsync(this.searchAchievementText);
        this.foundAchievements = achievementList;
      }
      catch (err) {
        if (err === 'service_unavailable') {
          this.apiError();
        }
      }
      this.isSearchingAchievements = false;
    },
    searchCollections: async function () {
      this.foundCollections = [];
      this.isSearchingCollections = true;
      this.apiSearch();
      try {
        const collectionList = await listCollectionsAsync(this.searchCollectionText);
        this.foundCollections = collectionList;
      }
      catch (err) {
        if (err === 'service_unavailable') {
          this.apiError();
        }
      }
      this.isSearchingCollections = false;
    },
    achievementIsSelected(achievement) {
      for (const item of this.achievements) {
        if (item.id == achievement.id) {
          return true;
        }
      }
      return false;
    },
    collectionIsSelected(collection) {
      for (const item of this.collections) {
        if (item.id == collection.id) {
          return true;
        }
      }
      return false;
    },
    addAchievement(achievement) {
      if (!this.achievementIsSelected(achievement)) {
        this.achievements.push(achievement);
        this.saveAchievementsToStorage();

        this.updateLink();
      }
    },
    addCollection(collection) {
      if (!this.collectionIsSelected(collection)) {
        this.collections.push(collection);
        this.saveCollectionsToStorage();

        this.updateLink();
      }
    },
    getAchievementIndex(achievement) {
      for (let i = 0; i < this.achievements.length; i++) {
        if (this.achievements[i].id == achievement.id) {
          return i;
        }
      }
      return -1;
    },
    getCollectionIndex(collection) {
      for (let i = 0; i < this.collections.length; i++) {
        if (this.collections[i].id == collection.id) {
          return i;
        }
      }
      return -1;
    },
    removeAchievement(achievement) {
      let idx = this.getAchievementIndex(achievement);
      if (idx >= 0) {
        this.achievements.splice(idx, 1);
        this.saveAchievementsToStorage();

        this.updateLink();
      }
    },
    removeCollection(collection) {
      let idx = this.getCollectionIndex(collection);
      if (idx >= 0) {
        this.collections.splice(idx, 1);
        this.saveCollectionsToStorage();

        this.updateLink();
      }
    },
    moveAchievementUp(achievement) {
      let idx = this.getAchievementIndex(achievement);
      if (idx > 0) {
        this.achievements.splice(idx, 1);
        this.achievements.splice(idx - 1, 0, achievement);

        this.updateLink();
      }
    },
    moveCollectionUp(collection) {
      let idx = this.getCollectionIndex(collection);
      if (idx > 0) {
        this.collections.splice(idx, 1);
        this.collections.splice(idx - 1, 0, collection);

        this.updateLink();
      }
    },
    moveAchievementDown(achievement) {
      let idx = this.getAchievementIndex(achievement);
      if (idx < this.achievements.length - 1) {
        this.achievements.splice(idx, 1);
        this.achievements.splice(idx + 1, 0, achievement);

        this.updateLink();
      }
    },
    moveCollectionDown(collection) {
      let idx = this.getCollectionIndex(collection);
      if (idx < this.collections.length - 1) {
        this.collections.splice(idx, 1);
        this.collections.splice(idx + 1, 0, collection);

        this.updateLink();
      }
    },
    findCharAchievement(char, achivementId) {
      for (const achievement of char.achievements) {
        if (achievement.id == achivementId) {
          return achievement;
        }
      }
      return null;
    },
    findCharCollection(char, collectionId) {
      for (const collection of char.collections) {
        if (collection.id == collectionId) {
          return collection;
        }
      }
      return null;
    },
    saveNewAchievementGroup() {
      if (this.newAchievementGroupName) {
        addOrReplaceAchievementGroup(this.newAchievementGroupName, this.achievements);
        this.loadSavedAchievementGroups();
        this.selectedAchievementGroupName = this.newAchievementGroupName;
      }
    },
    loadSavedAchievementGroup() {
      if (this.selectedAchievementGroupName) {
        const achievementGroup = loadAchievementGroup(this.selectedAchievementGroupName);
        if (achievementGroup) {
          this.achievements = achievementGroup.achievements || [];
          this.newAchievementGroupName = achievementGroup.name;

          this.updateTitle();
          this.updateLink();
        }
      }
    },
    removeSavedAchievementGroup() {
      if (this.selectedAchievementGroupName) {
        removeAchievementGroup(this.selectedAchievementGroupName);
        this.loadSavedAchievementGroups();

        this.updateTitle();
        this.updateLink();
      }
    },
    loadSavedAchievementGroups() {
      this.savedAchievementGroups.splice(0, this.savedAchievementGroups.length);
      this.savedAchievementGroups.push(...getAchievementGroups());
    },
    getCharLink: function(char) {
      if (char != null) {
        return `${window.location.origin}${publicPath}?char=${char.server}.${char.name}&autoload`;
      }
      return "";
    },
    copyAchievementLink: function(achievement, event) {
      const origText = event.target.innerHTML;
      event.target.innerHTML = 'Copied';
      setTimeout(() => { event.target.innerHTML = origText }, 1000);
      navigator.clipboard.writeText(achievement.gameLink);
    },
    copyCollectionLink: function(collection, event) {
      const origText = event.target.innerHTML;
      event.target.innerHTML = 'Copied';
      setTimeout(() => { event.target.innerHTML = origText }, 1000);
      navigator.clipboard.writeText(collection.gameLink);
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  margin-top: 1em;
  font-size: 1.4em;
}
h3 {
  margin-top: 1em;
  font-size: 1.2em;

  .btn.btn-simple:first-child {
    margin-left: 1em;
  }
}

.achievement-section,
.collection-section,
.gear-section {
  margin-top: 2em;
  padding: 1em;
  background-color: #fcfcfc;
  border: 1px solid #dddddd;
  border-radius: 5px;
}

#foundAchievements,
#foundCollections {
  margin: 0 0.25em;
  padding: 0.5em;
  background-color: #ededed;
  border: 1px solid #cccccc;
  border-radius: 3px;

  table {
    td {
      &:first-child {
        padding-right: 1em;
      }
    }
  }

  & > .btn {
    margin-top: 0.5em;
  }
}

.btn.btn-simple {
  margin: 0.15em;
  padding: 0.3em 1em;
}

.copy-achievement-link,
.copy-collection-link {
  cursor: pointer;
  border: 1px solid;
}

.achievements,
.collections {
  margin-top: 2em;

  h3 {
    margin: 1em 0 0.3em 0;
  }
  .achievement-description,
  .collection-description {
    display: inline-flex;
    align-items: center;
    gap: 1em;
    margin: 0 0 0.5em 0;
    padding: 0.1em 0.5em;
    font-size: 0.9em;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: #ededed;

    .achievement-description-text,
    .collection-description-text {
      display: inline-block;
    }
    .achievement-description-controls,
    .collection-description-controls {
      flex-shrink: 0;
    }
  }
  .char-achievement-success,
  .char-collection-success {
    margin-left: 0.25em;
    color: #009700;
  }
  .char-achievement-fail,
  .char-collection-fail {
    margin-left: 0.25em;
    color: #b30600;
  }
}
.gears {
  margin-top: 2em;

  table {
    margin-left: 1em;

    tr:nth-child(even) {
      background: #e2e2e2;
    }
    tr:last-of-type {
      td {
        border-bottom: 1px solid #e2e2e2;
      }
    }
    th {
      padding: 0.3em 1em;
      background-color: #555;
      color: #ffffff;
      &:first-of-type {
        border-top-left-radius: 5px;
      }
      &:last-of-type {
        border-top-right-radius: 5px;
      }
    }
    td {
      padding: 0.3em 1em;
      &:first-of-type {
        border-left: 1px solid #e2e2e2;
      }
      &:last-of-type {
        border-right: 1px solid #e2e2e2;
      }
    }
  }
}

.achievement-tooltip,
.collection-tooltip {
  position: relative;

  .achievement-tooltip-text,
  .collection-tooltip-text {
    visibility: hidden;
    background-color: black;
    color: #ffffff;
    text-align: left;
    padding: 0em 0.5em 0.25em 0.5em;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    width: 30ch;
  }

  &:hover {
    .achievement-tooltip-text,
    .collection-tooltip-text {
      visibility: visible;
    }
  }
}

.char-achievement,
.char-collection,
.char-gear {
  display: inline-block;
  margin-top: 0.1em;
  margin-left: 1em;
}

.char-achievement-count,
.char-collection-count,
.char-gear-count {
  display: inline-block;
  margin-left: 0.25em;
  padding: 0px 5px 0px 5px;
  font-size: 0.9rem;
  background-color: #f0db66;
  border: 1px solid #caac00;
  border-radius: 3px;
}

table.report {
  margin: 0;
}
</style>

<style lang="scss">
.achievement-tooltip-text,
.collection-tooltip-text {
  .step-needed,
  .item-needed {
    display: block;
  }
}
</style>