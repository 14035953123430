<template>
  <tr :class="getDecorationClasses"><td>{{ statName }}</td><td>{{ formatNumber(statValue) }}</td></tr>
</template>

<script>
export default {
  name: 'CharStatsItem',
  props: {
    statName: String,
    statValue: Number
  },
  data: function() {
    return {}
  },
  computed: {
    getDecorationClasses: function() {
      let classes = [];
      if (this.statValue <= 0) {
        classes.push('zero-val');
      }
      return classes;
    },
  },
  methods: {
    formatNumber: function(number) {
      return number >= 10000 ? (number).toLocaleString() : number;
    }
  }
}
</script>

<style lang="scss" scoped>
.zero-val > td {
  color: #aaaaaa;
}
td {
  padding: 0.3em 1em 0.3em 0.3em;
  text-align: left;
}
</style>
