<template>
<div class="char-stats-preview">
  <div v-if="showCompare" class="form-check compare">
    <input class="form-check-input" type="checkbox" :id="'compare' + _uid" v-model="comparing" @click="handleClick" :disabled="compareEnabled">
    <label class="form-check-label" :for="'compare' + _uid">Compare</label>
  </div>
  <div class="header">
    <div class="info">
      <div class="name"><a :href="getCharLink" target="_blank" rel="nofollow">{{ char.name }}</a></div>
      <div>{{ char.class }},&nbsp;&nbsp;<a :href="getGuildLink" target="_blank" rel="nofollow">{{ char.guild}}</a></div>
    </div>
    <div class="actions">
      <button v-if="showAdd" type="button" class="btn btn-primary" @click="$emit('addGroup', char)">Add</button>
      <button v-if="showRemove" type="button" class="btn btn-primary" @click="$emit('removeGroup', char)">Remove</button>
    </div>
  </div>
  <div class="stats">
    <div><span class="badge bg-warning text-dark">{{ char.resolve.total }}</span><span class="badge-label">Resolve</span></div>
    <div><span class="badge bg-warning text-dark">{{ char.potency.total.toLocaleString() }}</span><span class="badge-label">Potency</span></div>
    <div><span class="badge bg-warning text-dark">{{ char.cb.total.toLocaleString() }}</span><span class="badge-label">CB</span></div>
    <div><span class="badge bg-warning text-dark">{{ char.fervor.total }}</span><span class="badge-label">Fervor</span></div>
  </div>
  <div v-if="showResolveWeapons" class="resolve">
    <div v-if="filterResolveCutoff(char.gear.primary.amount)"><span class="badge bg-warning text-dark">{{ char.gear.primary.amount }}</span><span class="badge-label">Main</span></div>
    <div v-if="filterResolveCutoff(char.gear.secondary.amount)"><span class="badge bg-warning text-dark">{{ char.gear.secondary.amount }}</span><span class="badge-label">Second</span></div>
    <div v-if="filterResolveCutoff(char.gear.ranged.amount)"><span class="badge bg-warning text-dark">{{ char.gear.ranged.amount }}</span><span class="badge-label">Ranged</span></div>
  </div>
  <div v-if="showResolveGear" class="resolve">
    <div v-if="filterResolveCutoff(char.gear.head.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.head)">{{ char.gear.head.amount }}</span><span class="badge-label">Head</span></div>
    <div v-if="filterResolveCutoff(char.gear.chest.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.chest)">{{ char.gear.chest.amount }}</span><span class="badge-label">Chest</span></div>
    <div v-if="filterResolveCutoff(char.gear.shoulders.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.shoulders)">{{ char.gear.shoulders.amount }}</span><span class="badge-label">Shoulders</span></div>
    <div v-if="filterResolveCutoff(char.gear.forearms.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.forearms)">{{ char.gear.forearms.amount }}</span><span class="badge-label">Forearms</span></div>
    <div v-if="filterResolveCutoff(char.gear.hands.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.hands)">{{ char.gear.hands.amount }}</span><span class="badge-label">Hands</span></div>
    <div v-if="filterResolveCutoff(char.gear.legs.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.legs)">{{ char.gear.legs.amount }}</span><span class="badge-label">Legs</span></div>
    <div v-if="filterResolveCutoff(char.gear.feet.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.feet)">{{ char.gear.feet.amount }}</span><span class="badge-label">Feet</span></div>
  </div>
  <div v-if="showResolveJewelry" class="resolve">
    <div v-if="filterResolveCutoff(char.gear.ears.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.ears)">{{ char.gear.ears.amount }}</span><span class="badge-label">L Ear</span></div>
    <div v-if="filterResolveCutoff(char.gear.ears2.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.ears2)">{{ char.gear.ears2.amount }}</span><span class="badge-label">R Ear</span></div>
    <div v-if="filterResolveCutoff(char.gear.left_wrist.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.left_wrist)">{{ char.gear.left_wrist.amount }}</span><span class="badge-label">L Wrist</span></div>
    <div v-if="filterResolveCutoff(char.gear.right_wrist.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.right_wrist)">{{ char.gear.right_wrist.amount }}</span><span class="badge-label">R Wrist</span></div>
    <div v-if="filterResolveCutoff(char.gear.left_ring.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.left_ring)">{{ char.gear.left_ring.amount }}</span><span class="badge-label">L Ring</span></div>
    <div v-if="filterResolveCutoff(char.gear.right_ring.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.right_ring)">{{ char.gear.right_ring.amount }}</span><span class="badge-label">R Ring</span></div>
  </div>
  <div v-if="showResolveExtra" class="resolve">
    <div v-if="filterResolveCutoff(char.gear.cloak.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.cloak)">{{ char.gear.cloak.amount }}</span><span class="badge-label">Cloak</span></div>
    <div v-if="filterResolveCutoff(char.gear.neck.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.neck)">{{ char.gear.neck.amount }}</span><span class="badge-label">Neck</span></div>
    <div v-if="filterResolveCutoff(char.gear.waist.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.waist)">{{ char.gear.waist.amount }}</span><span class="badge-label">Waist</span></div>
    <div v-if="filterResolveCutoff(char.gear.activate1.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.activate1)">{{ char.gear.activate1.amount }}</span><span class="badge-label">L Charm</span></div>
    <div v-if="filterResolveCutoff(char.gear.activate2.amount)"><span class="badge bg-warning text-dark" :class="getGearClass(char.gear.activate2)">{{ char.gear.activate2.amount }}</span><span class="badge-label">R Charm</span></div>
  </div>
</div>
</template>

<script>
import { publicPath } from "../../vue.config";

export default {
  name: 'CharStatsPreview',
  props: {
    char: Object,
    showAdd: Boolean,
    showRemove: Boolean,
    showCompare: Boolean,
    disableCompare: Boolean,
    showResolveWeapons: Boolean,
    showResolveGear: Boolean,
    showResolveJewelry: Boolean,
    showResolveExtra: Boolean,
    resolveCutoff: Number,
  },
  data: function() {
    return {
      comparing: false
    }
  },
  computed: {
    compareEnabled: function() {
      return !this.comparing && this.disableCompare;
    },
    getCharLink: function() {
      if (this.char != null) {
        return `${window.location.origin}${publicPath}?char=${this.char.server}.${this.char.name}&autoload`;
      }
      return "";
    },
    getGuildLink: function() {
      if (this.char?.guildId ?? null != null) {
        return `https://u.eq2wire.com/soe/guild_detail/${this.char.guildId}`;
      }
      return "";
    }
  },
  methods: {
    handleClick() {
      // The logic is backwards since the click handler fires before the state is updated
      if (!this.comparing) {
        this.$emit('addCompare', this.char);
      }
      else {
        this.$emit('removeCompare', this.char);
      }
    },
    filterResolveCutoff(amount) {
      if (isNaN(this.resolveCutoff)) {
        return true;
      }
      return amount > 0 && amount <= this.resolveCutoff;
    },
    getGearClass(gearItem) {
      let cssClass = '';
      if (gearItem.cboc && !gearItem.isWeapon) {
        cssClass = 'cboc';
      }
      else if (gearItem.adorns.purple.count > 1) {
        cssClass = 'purples';
      }
      return cssClass;
    }
  }
}
</script>

<style lang="scss" scoped>
.char-stats-preview {
  margin-bottom: 0.75em;
  padding: 0.75em;
  background-color: #fbfbfb;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
.compare {
  display: inline-block;
  margin-bottom: 1em;;
  padding: 0.25em 0.5em;
  background-color: rgb(232, 243, 255);
  border: 1px solid rgb(161, 206, 252);
  border-radius: 4px;

  .form-check-input {
    margin-left: 0;
    margin-right: 0.5em;
  }
}
.header {
  display: flex;
}
.info {
  flex-grow: 1;

  & > div {
    display: inline-block;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.name {
  min-width: 12ch;
  font-size: 1.1em;
  font-weight: bold;
}
.badge {
  font-size: 1em;
  margin-right: 0.25em;
  background-color: #def !important;
  border: 1px solid rgb(161, 206, 252);
}
.badge-label {
  display: inline-block;
  min-width: 4ch;
  font-size: 0.9em;
}
.stats {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em 1.5em;
  margin: 0.25em 0;
  color: #666;
}
.resolve {
  display: flex;
  flex-wrap: wrap;
  gap: 0.2em 0.5em;
  margin: 0.25em 0;
  color: #666;

  .badge {
    background-color: #f3ffbe !important;
    border: 1px solid #e9d207;
    &.cboc {
      background-color: #f1c8a6 !important;
      border: 1px solid #c75a00;
    }
    &.purples {
      background-color: #d5c3da !important;
      border: 1px solid #8f00bb;
    }
  }
  .badge-label {
    min-width: 8ch;
  }
}
</style>
