import Vue from 'vue';
import Vuex from 'vuex';
import { addToLocalStorage, getFromLocalStorage } from '../util/storage.js';

Vue.use(Vuex);

function addItemToStore(state, item) {
  state.items[item.id] = item;
}
function addSpellToStore(state, spell) {
  state.spells[spell.id] = spell;
}
function addAchievementToStore(state, achievement) {
  state.achievements[achievement.id] = achievement;
}
function addCollectionToStore(state, collection) {
  state.collections[collection.id] = collection;
}
function addPlayerToStore(state, player) {
  state.players[player.id] = player;
  const key = `${player.server}.${player.name}`;
  state.players[key] = player;
}

export default new Vuex.Store({
  state: {
    items: {},
    spells: {},
    achievements: {},
    collections: {},
    players: {}
  },
  getters: {
    getItem: (state) => (itemId) => {
      let item = undefined;

      if (itemId) {
        // If the item is not found in the store, try to get it from the browser local storage
        item = state.items[itemId];
        if (!item) {
          const itemJson = getFromLocalStorage(`item.${itemId}`);
          if (itemJson) {
            item = JSON.parse(itemJson);

            // And add it to the store so it's available next time
            addItemToStore(state, item);
          }
        }
      }

      return item;
    },
    getSpell: (state) => (spellId) => {
      let spell = undefined;

      if (spellId) {
        // If the item is not found in the store, try to get it from the browser local storage
        spell = state.spells[spellId];
        if (!spell) {
          const spellJson = getFromLocalStorage(`spell.${spellId}`);
          if (spellJson) {
            spell = JSON.parse(spellJson);

            // And add it to the store so it's available next time
            addSpellToStore(state, spell);
          }
        }
      }

      return spell;
    },
    getAchievement: (state) => (achievementId) => {
      let achievement = undefined;

      if (achievementId) {
        // If the item is not found in the store, try to get it from the browser local storage
        achievement = state.achievements[achievementId];
        if (!achievement) {
          const achievementJson = getFromLocalStorage(`achievement.${achievementId}`);
          if (achievementJson) {
            achievement = JSON.parse(achievementJson);

            // And add it to the store so it's available next time
            addAchievementToStore(state, achievement);
          }
        }
      }

      return achievement;
    },
    getCollection: (state) => (collectionId) => {
      let collection = undefined;

      if (collectionId) {
        // If the item is not found in the store, try to get it from the browser local storage
        collection = state.collections[collectionId];
        if (!collection) {
          const collectionJson = getFromLocalStorage(`achievement.${collectionId}`);
          if (collectionJson) {
            collection = JSON.parse(collectionJson);

            // And add it to the store so it's available next time
            addCollectionToStore(state, collection);
          }
        }
      }

      return collection;
    },
    getPlayerById: (state) => (playerId) => {
      let player = undefined;

      // If the item is not found in the store, try to get it from the browser local storage
      if (playerId) {
        player = state.players[playerId];
        if (!player) {
          const playerJson = getFromLocalStorage(`player.${playerId}`);
          if (playerJson) {
            player = JSON.parse(playerJson);

            // And add it to the store so it's available next time
            addPlayerToStore(state, player);
          }
        }
      }

      return player;
    },
    getPlayerByName: (state) => (server, name) => {
      let player = undefined;

      // If the item is not found in the store, try to get it from the browser local storage
      const playerKey = `${server}.${name}`;
      player = state.players[playerKey];
      if (!player) {
        const playerJson = getFromLocalStorage(`player.${playerKey}`);
        if (playerJson) {
          player = JSON.parse(playerJson);

          // And add it to the store so it's available next time
          addPlayerToStore(state, player);
        }
      }

      return player;
    }
  },
  mutations: {
    addItem(state, item) {
      if (item?.id != null && !Object.prototype.hasOwnProperty.call(state.items, item.id)) {
        addItemToStore(state, item);

        // Also store it in browser storage for faster retrieval later
        addToLocalStorage(`item.${item.id}`, JSON.stringify(item));
      }
    },
    addSpell(state, spell) {
      if (spell?.id != null && !Object.prototype.hasOwnProperty.call(state.spells, spell.id)) {
        addSpellToStore(state, spell);

        // Also store it in browser storage for faster retrieval later
        addToLocalStorage(`spell.${spell.id}`, JSON.stringify(spell));
      }
    },
    addAchievement(state, achievement) {
      if (achievement?.id != null && !Object.prototype.hasOwnProperty.call(state.achievements, achievement.id)) {
        addAchievementToStore(state, achievement);

        // Also store it in browser storage for faster retrieval later
        addToLocalStorage(`achievement.${achievement.id}`, JSON.stringify(achievement));
      }
    },
    addCollection(state, collection) {
      if (collection?.id != null && !Object.prototype.hasOwnProperty.call(state.collections, collection.id)) {
        addCollectionToStore(state, collection);

        // Also store it in browser storage for faster retrieval later
        addToLocalStorage(`collection.${collection.id}`, JSON.stringify(collection));
      }
    },
    addOrReplacePlayer(state, player) {
      if (player?.id != null) {
        if (Object.prototype.hasOwnProperty.call(state.players, player.id)) {
          delete state.players[player.id];
        }

        addPlayerToStore(state, player);

        // Also store it in browser storage for faster retrieval later
        const playerJson = JSON.stringify(player);
        try {
          let key = `player.${player.id}`;
          addToLocalStorage(key, playerJson);
          key = `player.${player.server}.${player.name}`;
          addToLocalStorage(key, playerJson);
        }
        catch (err) {
          console.error(err);
        }
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
