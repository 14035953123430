<template>
  <div class="container-xxl">
    <div class="content">
      <div class="header">
        <h1>
          <a class="link-light" href="#" onclick="window.location.reload(true);">EQ2 Compare</a>
          <a v-if="link" class="link-primary" :href="link"><font-awesome-icon :icon="['fas', 'share-square']" /></a>
        </h1>
        <div class="links">
          <router-link to="/">Compare</router-link>
          <router-link to="/group">Groups</router-link>
          <router-link to="/report">Report</router-link>
        </div>
      </div>

      <div v-if="hasApiError" class="alert alert-danger" role="alert">
        There was a problem connecting to the EQ2 Census API.
      </div>

      <router-view
        @apiError="apiError"
        @apiSearch="apiSearch"
        @newLink="newLink">
      </router-view>
    </div>

    <div class="footer">
      <div class="row">
        <div class="d-flex justify-content-between">
          <div>
            <a href="https://github.com/eq2reapp/eq2cmp-host/issues" target="_blank">Report an issue or make a suggestion for this site</a>
          </div>
          <div class="version-string">v{{ version }} <a href="javascript:void(0);" @click="clearStorage">(Clear stored data)</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clearStorage } from '../util/storage.js';

export default {
  name: 'Main',
  data: function() {
    return {
      link: "",
      hasApiError: false
    }
  },
  props: [
    'version'
  ],
  methods: {
    apiError: function() {
      this.hasApiError = true;
    },
    apiSearch: function() {
      this.hasApiError = false;
    },
    newLink: function(link) {
      this.link = link;
    },
    clearStorage() {
      if (confirm("Clearing stored data will make room for new data. Clear stored data now?")) {
        clearStorage();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-xxl {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
  @media only screen and (min-width: 960px) {
    width: 95%;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0.5em;
  padding: 0.75em 0.5em;
  background-color: #3a3a3a;
  color: #dddddd;
  border-bottom: 3px solid #666666;


  h1 {
    flex-grow: 1;
    font-size: 1.4em;
    @media only screen and (max-width: 600px) {
      font-size: 1.1em;
    }
  }
  .links {
    margin: 0 1em;

    a {
      margin: 0 0.5em;
      text-decoration: none;
      font-size: 1em;
      font-weight: 600;
      color: #ffffff;
      &:hover {
        color: #388eff;
      }
      @media only screen and (max-width: 600px) {
        font-size: 1em;
      }
    }
  }
}

h1 {
  display: inline-block;
  font-size: 1.8rem;
  margin: 0;

  a {
    text-decoration: none;
  }
  svg {
    margin-left: 0.5em;
  }
}

.footer {
  margin-top: 3em;
	padding: 1em;
	background-color: #f8f8f8;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  a {
    color: #3a3a3a;
  }

  .version-string {
    color: #cccccc;
    a {
      margin-left: 1em;
      color: #cccccc;
    }
  }
}
</style>
