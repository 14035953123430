<template>
  <div id="app">
    <Main :version="version"></Main>
  </div>
</template>

<script>
import { publicPath } from '../vue.config'
import Main from './components/Main.vue'
import axios from 'axios';

export default {
  name: 'App',
  data: function() {
    return {
      version: ''
    }
  },
  components: {
    Main
  },
  methods: {
    async getVersion() {
      var version = '';
      var versionUrl = `${location.origin}${publicPath}version.html`;
      try {
        version = (await axios.get(versionUrl)).data.trim();
      }
      catch (err) {
        console.error(err);
      }
      return version;
    }
  },
  async mounted() {
    this.version = (await this.getVersion());
  }
}
</script>

<style lang="scss">
$secondary: #ccc;
@import 'bootstrap';

@import url(//fonts.googleapis.com/css?family=Lato);

html {
  font-size: 16px;
  height: 100%;
}

body {
  height: 100%;
}

#app {
  font-family: Lato, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
</style>
