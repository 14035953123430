import lzstring from 'lz-string';

const GROUP_KEYS = 'group.__keys';
const ACHIEVEMENT_GROUP_KEYS = 'achieveGroup.__keys';

export function pruneOldPlayers() {
    // Clean up old players since they take a sizable chunk of local storage.
    var playerFetchTimes = [];
    var numStoredPlayers = 0;
    for (const key in localStorage) {
        if (key.startsWith('c.player.')) {
            const player = JSON.parse(getFromLocalStoragePrivate(key));
            const now = Date.now() / 1000.0;
            const cutoff = 60 * 60 * 24 * 7; // 7 Days
            if ((player.fetchTime ?? 0) > now || ((now - (player.fetchTime ?? 0)) > cutoff)) {
                localStorage.removeItem(key);
            }
            else {
                numStoredPlayers++;
                playerFetchTimes.push({ key: key, fetchTime: player.fetchTime });
            }
        }
    }

    // Also cap the number stored.
    const maxStoredPlayers = 40;
    if (numStoredPlayers > maxStoredPlayers) {
        playerFetchTimes.sort((x, y) => {
            if (x.fetchTime < y.fetchTime) {
                return -1;
            }
            if (x.fetchTime > y.fetchTime) {
                return 1;
            }
            return 0;
        });
        for (var i = 0; i < (playerFetchTimes.length - maxStoredPlayers); i++) {
            const key = playerFetchTimes[i].key;
            localStorage.removeItem(key);
        }
    }
}

export function clearStorage() {
    for (const key in localStorage) {
        if (key.startsWith('c.player.') ||
            key.startsWith('c.item.')) {
                localStorage.removeItem(key);
        }
    }
}

export function addToLocalStorage(key, value) {
    const compressed = lzstring.compress(value);
    const actualKey = `c.${key}`;
    try {
        localStorage.removeItem(actualKey);
        localStorage.setItem(actualKey, compressed);
    }
    catch (err) {
        console.error(err);
        const storageUsedMb = ((new Blob(Object.values(localStorage)).size) / 1000000.0).toFixed(2);
        console.warn(`Storage space exceeded: ${storageUsedMb} MB. Pruning old stored players to clear space`);

        // Try to save again
        pruneOldPlayers();
        try {
            localStorage.setItem(actualKey, compressed);
        }
        catch (err) {
            console.error(`Second storage attempt failed for ${actualKey}`);
        }

    }
}

function getFromLocalStoragePrivate(key) {
    const compressed = localStorage.getItem(key);
    if (compressed) {
        return lzstring.decompress(compressed);
    }
    return null;
}

export function getFromLocalStorage(key) {
    // Support older clients by wiping out their data!
    const usesCompressedStorage = localStorage.getItem('app.compressedStorage');
    if (!usesCompressedStorage) {
        console.warn('This app now uses compressed storage. Any existing cached data will now be cleared.');

        // Remove all items whose keys are not of the newer type, or for groups
        const purgeKeys = [];
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key != GROUP_KEYS && !key.startsWith('c.') && !key.startsWith('group.')) {
                purgeKeys.push(key);
            }
        }
        for (const key of purgeKeys) {
            localStorage.removeItem(key);
        }

        try {
            localStorage.setItem('app.compressedStorage', 'true');
        }
        catch (err) {
            console.error(err);
        }
    }

    return getFromLocalStoragePrivate(`c.${key}`);
}

export function getGroups() {
    const groups = [];
    let keys = localStorage.getItem(GROUP_KEYS);
    if (keys) {
        keys = JSON.parse(keys);
        const loadedKeys = [];
        keys.forEach(key => {
            if (!loadedKeys.includes(key)) {
                let group = localStorage.getItem(key);
                if (group) {
                    groups.push(JSON.parse(group));
                }
                loadedKeys.push(key);
            }
        });
    }
    return groups;
}

export function loadGroup(groupName) {
    for (const group of getGroups()) {
        if (group.name == groupName) {
            return group;
        }
    }
    return null;
}

export function groupExists(groupName) {
    return loadGroup(groupName) != null;
}

export function removeGroup(groupName) {
    let groups = getGroups();
    const existingGroupIndex = getGroupIndex(groups, groupName);
    if (existingGroupIndex >= 0) {
        localStorage.removeItem(getGroupKey(groupName));
        groups.splice(existingGroupIndex, 1);
        saveGroupKeys(groups);
    }
}

export function addOrReplaceGroup(groupName, charIds) {
    if (groupName.length < 1) {
        this.message = 'You must provide a group name.'
        return false;
    }

    if (groupExists(groupName)) {
        removeGroup(groupName);
    }

    const newGroup = {
        key: getGroupKey(groupName),
        name: groupName,
        ids: charIds
    };
    localStorage.setItem(newGroup.key, JSON.stringify(newGroup));

    const groups = getGroups();
    // The groups will not yet contain the new or replaced group, since the key
    // is not part of the collection (and therefore will not be loaded), so add
    // it manually.
    groups.push(newGroup);
    saveGroupKeys(groups);

    return true;
}

function saveGroupKeys(groups) {
    const keyStr = JSON.stringify(groups.map(save => { return save.key}));
    try {
        localStorage.setItem(GROUP_KEYS, keyStr);
    }
    catch (err) {
        console.error("Could not save group keys in local storage: " + err);
    }
}

function getGroupKey(groupName) {
    return 'group.' + groupName.replace(/\s/g, '');
}

function getGroupIndex(groups, groupName) {
    for (let i = 0; i < groups.length; i++) {
        if (groups[i].name == groupName) {
            return i;
        }
    }
    return -1;
}

export function getAchievementGroups() {
    const achievementGroups = [];
    let keys = localStorage.getItem(ACHIEVEMENT_GROUP_KEYS);
    if (keys) {
        keys = JSON.parse(keys);
        const loadedKeys = [];
        keys.forEach(key => {
            if (!loadedKeys.includes(key)) {
                let achievementGroup = localStorage.getItem(key);
                if (achievementGroup) {
                    achievementGroups.push(JSON.parse(achievementGroup));
                }
                loadedKeys.push(key);
            }
        });
    }
    return achievementGroups;
}

export function loadAchievementGroup(achievementGroupName) {
    for (const achievementGroup of getAchievementGroups()) {
        if (achievementGroup.name == achievementGroupName) {
            return achievementGroup;
        }
    }
    return null;
}

export function achievementGroupExists(achievementGroupName) {
    return loadAchievementGroup(achievementGroupName) != null;
}

export function removeAchievementGroup(achievementGroupName) {
    let achievementGroups = getAchievementGroups();
    const existingAchievementGroupIndex = getAchievementGroupIndex(achievementGroups, achievementGroupName);
    if (existingAchievementGroupIndex >= 0) {
        localStorage.removeItem(getAchievementGroupKey(achievementGroupName));
        achievementGroups.splice(existingAchievementGroupIndex, 1);
        saveAchievementGroupKeys(achievementGroups);
    }
}

export function addOrReplaceAchievementGroup(achievementGroupName, achievements) {
    if (achievementGroupName.length < 1) {
        this.message = 'You must provide an achievement group name.'
        return false;
    }

    if (achievementGroupExists(achievementGroupName)) {
        removeAchievementGroup(achievementGroupName);
    }

    const newAchievementGroup = {
        key: getAchievementGroupKey(achievementGroupName),
        name: achievementGroupName,
        achievements: achievements
    };
    localStorage.setItem(newAchievementGroup.key, JSON.stringify(newAchievementGroup));

    const achievementGroups = getAchievementGroups();
    // The achievement groups will not yet contain the new or replaced achievements, since the key
    // is not part of the collection (and therefore will not be loaded), so add
    // it manually.
    achievementGroups.push(newAchievementGroup);
    saveAchievementGroupKeys(achievementGroups);

    return true;
}

function saveAchievementGroupKeys(achievementGroups) {
    const keyStr = JSON.stringify(achievementGroups.map(save => { return save.key}));
    try {
        localStorage.setItem(ACHIEVEMENT_GROUP_KEYS, keyStr);
    }
    catch (err) {
        console.error("Could not save achievement group keys in local storage: " + err);
    }
}

function getAchievementGroupKey(achievementGroupName) {
    return 'achieveGroup.' + achievementGroupName.replace(/\s/g, '');
}

function getAchievementGroupIndex(achievementGroups, achievementGroupName) {
    for (let i = 0; i < achievementGroups.length; i++) {
        if (achievementGroups[i].name == achievementGroupName) {
            return i;
        }
    }
    return -1;
}
