import Vue from 'vue'
import VueRouter from 'vue-router'
import { publicPath } from '../vue.config'
import App from './App.vue'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle, faExclamationCircle, faDove, faFrog, faShareSquare, faPlus, faTimes, faLevelUpAlt, faCheck, faExclamationTriangle, faAngleDown, faAngleUp, faLink, faUserNurse } from '@fortawesome/free-solid-svg-icons'
import { faSun } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Compare from './components/Compare'
import Group from './components/Group'
import Report from './components/Report'

Vue.config.productionTip = false
// Vue.config.devtools = true

library.add(faInfoCircle)
library.add(faExclamationCircle)
library.add(faDove)
library.add(faFrog)
library.add(faShareSquare)
library.add(faSun)
library.add(faPlus)
library.add(faTimes)
library.add(faLevelUpAlt)
library.add(faCheck)
library.add(faExclamationTriangle)
library.add(faAngleDown)
library.add(faAngleUp)
library.add(faLink)
library.add(faUserNurse)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueRouter)
const routes = [
  { path: '/group/:players*', component: Group, props: true },
  { path: '/group', component: Group },
  { path: '/report', component: Report },
  { path: '*', component: Compare }
]
const router = new VueRouter({
  routes: routes,
  mode: 'history',
  base: publicPath
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
