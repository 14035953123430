<template>
  <div class="row">
    <div class="col-sm">
      <CharSelector
        @apiError="apiError()"
        @apiSearch="apiSearch()"
        @charFound="(c) => charFound(0, c)"
        :serverHint="suggestCharPart(0, 'server')"
        :charHint="suggestCharPart(0, 'name')"
        :autoload="autoload">
      </CharSelector>
      <div v-if="chars[0]">
        <CharStats :char="chars[0]"></CharStats>
      </div>
      <div v-if="charsMessages[0]" class="alert alert-warning">{{ charsMessages[0] }}</div>
    </div>
    <div class="col-sm">
      <CharSelector
        @apiError="apiError()"
        @apiSearch="apiSearch()"
        @charFound="(c) => charFound(1, c)"
        :serverHint="suggestCharPart(1, 'server')"
        :charHint="suggestCharPart(1, 'name')"
        :autoload="autoload">
      </CharSelector>
      <div v-if="chars[1]">
        <CharStats :char="chars[1]"></CharStats>
      </div>
      <div v-if="charsMessages[1]" class="alert alert-warning">{{ charsMessages[1] }}</div>
    </div>
  </div>
</template>

<script>
import { publicPath } from '../../vue.config'
import CharSelector from './CharSelector';
import CharStats from './CharStats';

export default {
  name: 'Compare',
  components: {
    CharSelector,
    CharStats
  },
  data: function() {
    return {
      preloadChars: [],
      chars: [null, null],
      charsMessages: ['', ''],
      autoload: false
    }
  },
  props: [
    'version'
  ],
  created: function() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    this.preloadChars = urlSearchParams.getAll('char');
    this.autoload = (urlSearchParams.getAll('autoload') ?? []).length > 0;
  },
  computed: {
  },
  methods: {
    suggestCharPart: function(charIndex, part) {
      const partIndex = (part == 'name') ? 1 : 0;
      if (this.preloadChars.length > charIndex) {
        const parts = this.preloadChars[charIndex].split(".");
        if (parts.length > partIndex) return parts[partIndex];
      }
      else {
        const cookie = document.cookie
          .split('; ')
          .find(row => row.startsWith(`eq2cmp${charIndex + 1}_${part}=`)) ?? '=';
        const val = cookie.split('=')[1];
        if (val != undefined && val != null && val != '' && (part == 'server' || this.preloadChars.length < 1)) {
          return val;
        }
      }
      return '';
    },
    apiError: function() {
      this.$emit('apiError');
    },
    apiSearch: function() {
      this.$emit('apiSearch');
    },
    charFound: function(charIndex, charInfo) {
      this.$set(this.chars, charIndex, charInfo.char);
      if (charInfo.char) {
        this.setCookie(charIndex + 1, 'name', charInfo.charName);
        this.setCookie(charIndex + 1, 'server', charInfo.server);
        this.$set(this.charsMessages, charIndex, '');
      }
      else {
        this.$set(this.charsMessages, charIndex, `Could not find ${charInfo.charName}`);
      }
      this.updateTitle();
      this.updateLink();
    },
    setCookie: function(charNum, part, value) {
      document.cookie = `eq2cmp${charNum}_${part}=${value};max-age=31536000;SameSite=Strict;path=${document.location.pathname}`;
    },
    updateTitle: function() {
      var chars = [];
      for (var i = 0; i < this.chars.length; i++) {
        if (this.chars[i]) {
          chars.push(this.chars[i].name);
        }
      }
      const charStr = chars.join(", ");
      document.title = (charStr.length > 0 ? `${charStr} | ` : "") + "eq2cmp";
    },
    updateLink: function() {
      var chars = [];
      for (var i = 0; i < this.chars.length; i++) {
        if (this.chars[i]) {
          chars.push(`char=${this.chars[i].server}.${this.chars[i].name}`);
        }
      }
      const link = (chars.length < 1 ? "" : `${window.location.origin}${publicPath}?${chars.join("&")}`);
      this.$emit('newLink', link);
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
