<template>
  <div>
    <div class="row">
      <div class="col">
        <div
          v-if="message"
          class="alert alert-success alert-dismissible fade show">
          {{ message }}
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="message = ''">
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h2>Load group</h2>
        <div class="input-group">
          <select id="load" class="form-select" v-model="selectedGroupName">
            <option v-for="save in getSortedSavedGroups" :value="save.name" :key="save.name">{{ save.name }}</option>
          </select>
          <button class="btn btn-primary" type="button" @click="loadSavedGroup">Load</button>
          <button class="btn btn-secondary" type="button" @click="removeSavedGroup">Remove</button>
        </div>
      </div>
      <div class="col-md-6">
        <h2>Save group</h2>
        <div class="input-group">
          <input type="text" class="form-control" placeholder="New group name" v-model.trim="newGroupName">
          <button class="btn btn-primary" type="button" @click="saveNewGroup">Save</button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-9 col-lg-6">
        <h2>Find characters</h2>
        <CharSelector
          @apiError="apiError"
          @apiSearch="apiSearch"
          @charFound="charFound">
        </CharSelector>
        <div v-if="searchMessage" class="alert alert-warning">
          {{ searchMessage }}
        </div>
      </div>
    </div>
    <div v-if="searchChar" class="row found-char">
      <div class="col">
        <CharStatsPreview
          :char="searchChar"
          :showAdd="true"
          @addGroup="addToGroup">
        </CharStatsPreview>
      </div>
    </div>

    <!-- Sort -->
    <div class="row">
      <div class="col-md-9 col-lg-6">
        <h2>Sort by</h2>
        <div class="option-section">
          <select id="sort" class="form-select" v-model.trim="sort">
            <option value="name">Name</option>
            <option value="resolve">Resolve</option>
            <option value="potency">Potency</option>
            <option value="cb">Crit Bonus</option>
            <option value="fervor">Fervor</option>
          </select>
        </div>
      </div>
    </div>

    <!-- Options -->
    <div class="accordion" id="optionsAccordion">
      <div class="accordion-item">
        <h3 class="accordion-header" id="headingOne">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne">
            Options
          </button>
        </h3>
        <div
          id="collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#optionsAccordion">
          <div class="accordion-body">

            <div class="row">
              <div class="col-md-6">

                <!-- Show resolve -->
                <div class="option-section">
                  <div class="h5">Show resolve for</div>
                  <div class="resolve-checks">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="showResolveWeapons"
                        id="showResolveWeapons"/>
                      <label class="form-check-label" for="showResolveWeapons">Weapons</label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="showResolveGear"
                        id="showResolveGear"/>
                      <label class="form-check-label" for="showResolveGear">Gear</label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="showResolveJewelry"
                        id="showResolveJewelry"/>
                      <label class="form-check-label" for="showResolveJewelry">Jewelry</label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="showResolveExtra"
                        id="showResolveExtra"/>
                      <label class="form-check-label" for="showResolveExtra">Extra</label>
                    </div>
                  </div>
                </div>

                <div class="option-section">
                  <div class="form-group form-inline">
                    <label for="filterItemsByResolve">Only show items with resolve &lt;=</label>
                    <input type="text" class="form-control" id="filterItemsByResolve" v-model.number="resolveCutoff" @keypress="validateResolveCutoff($event)">
                  </div>
                </div>

                <!-- Compare -->
                <div class="option-section">
                  <div class="h5">Compare</div>
                  <div class="compare-actions">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="showCompare"
                        id="showCompare"/>
                      <label class="form-check-label" for="showCompare">Select characters</label>
                    </div>
                    <button
                      v-if="comparePossible"
                      type="button"
                      class="btn btn-primary"
                      ref="btnCompare"
                      @click="doCompare">
                      Compare
                    </button>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-for="char in getSortedChars" v-bind:key="char.id" class="row">
      <div class="col">
        <CharStatsPreview
          :char="char"
          :showRemove="true"
          :showCompare="showCompare"
          :disableCompare="disableCompare"
          :showResolveWeapons="showResolveWeapons"
          :showResolveGear="showResolveGear"
          :showResolveJewelry="showResolveJewelry"
          :showResolveExtra="showResolveExtra"
          :resolveCutoff="resolveCutoff"
          @removeGroup="removeFromGroup"
          @addCompare="addToCompare"
          @removeCompare="removeFromCompare">
        </CharStatsPreview>
      </div>
    </div>
  </div>
</template>

<script>
import { publicPath } from "../../vue.config";
import { getPlayerStatsByIdAsync } from "@/util/eqApi";
import { getGroups, loadGroup, addOrReplaceGroup, removeGroup } from "@/util/storage";
import CharSelector from "./CharSelector.vue";
import CharStatsPreview from "./CharStatsPreview.vue";

export default {
  name: "Group",
  components: {
    CharSelector,
    CharStatsPreview,
  },
  data: function () {
    return {
      searchChar: null,
      searchMessage: "",
      chars: [],
      message: "",
      sort: "name",
      showResolveWeapons: false,
      showResolveGear: false,
      showResolveJewelry: false,
      showResolveExtra: false,
      resolveCutoff: 10000,
      showCompare: false,
      disableCompare: false,
      compareChars: [],
      savedGroups: [],
      selectedGroupName: "",
      newGroupName: ""
    };
  },
  mounted: async function () {
    this.parseOpts();
    this.loadSavedGroups();
    if (this.newGroupName) {
      this.selectedGroupName = this.newGroupName;
      this.loadSavedGroup();
    }
  },
  watch: {
    $route: async function () {
      this.loadSavedGroups();
    },
  },
  computed: {
    getSortedChars: function () {
      let sorted = [...this.chars];
      switch (this.sort) {
        case "name":
          sorted.sort((x, y) => {
            return x.name.localeCompare(y.name);
          });
          break;
        case "resolve":
          sorted.sort((x, y) => {
            return y.resolve.total > x.resolve.total ? 1 : -1;
          });
          break;
        case "potency":
          sorted.sort((x, y) => {
            return y.potency.total > x.potency.total ? 1 : -1;
          });
          break;
        case "cb":
          sorted.sort((x, y) => {
            return y.cb.total > x.cb.total ? 1 : -1;
          });
          break;
        case "fervor":
          sorted.sort((x, y) => {
            return y.fervor.total > x.fervor.total ? 1 : -1;
          });
          break;
      }
      return sorted;
    },
    comparePossible() {
      return this.showCompare && this.compareChars.length >= 2;
    },
    getSortedSavedGroups() {
      const saves = [...this.savedGroups];
      saves.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      return saves;
    }
  },
  methods: {
    parseOpts: function() {
      const query = this.$route.query;
      if (query) {
        if (query.opts) {
          const opts = JSON.parse(atob(query.opts));
          if (opts.groupName) {
            addOrReplaceGroup(opts.groupName, opts.chars ?? [])
            this.newGroupName = opts.groupName;
          }
        }
      }
    },
    loadPlayers: async function (playerIds) {
      if (playerIds.length > 0) {

        this.apiSearch();
        this.message = `Loading ${playerIds.length} character${playerIds.length == 1 ? '' : 's'}...`;
        var loadingChars = [];
        for (const playerId of playerIds) {
          loadingChars.push(getPlayerStatsByIdAsync(playerId));
        }

        let loadedChars = [];
        let failedChars = [];
        let values = await Promise.allSettled(loadingChars);
        values.forEach(value => {
          if (value.status === 'fulfilled') {
            loadedChars.push(value.value);
          }
          else {
            if (value.reason === 'service_unavailable') {
              this.apiError();
            }
            failedChars.push(value.value);
          }
        });
        this.chars = loadedChars;
        if (failedChars.length > 0) {
          this.message = `Failed to load ${failedChars.length} of ${playerIds.length} characters`;
          failedChars.forEach((failedChar) => {
            console.error('Failed to load char', failedChar);
          });
        }
        else {
          this.message = "";
        }

        this.updateTitle();
        this.updateLink();

      } else {
        this.message = "";
      }
    },
    charFound: function (charInfo) {
      this.searchMessage = "";
      if (charInfo.char) {
        this.searchChar = charInfo.char;
      } else {
        this.searchMessage = `Could not find ${charInfo.charName}`;
      }
    },
    apiError: function () {
      this.$emit("apiError");
    },
    apiSearch: function () {
      this.$emit("apiSearch");
    },
    addToGroup(char) {
      if (char != null) {
        for (const existing of this.chars) {
          if (existing.id == char.id) {
            return;
          }
        }

        this.chars.push(char);
        this.message = "";
        this.searchChar = null;

        this.saveCurrentGroup();

        this.updateTitle();
        this.updateLink();
      }
    },
    removeFromGroup(char) {
      if (char != null) {
        var index = -1;
        for (var i = 0; i < this.chars.length; i++) {
          if (this.chars[i].id == char.id) {
            index = i;
            break;
          }
        }
        if (index >= 0) {
          this.chars.splice(index, 1);
          this.message = "";

          this.saveCurrentGroup();

          this.updateTitle();
          this.updateLink();
        }
      }
    },
    addToCompare(char) {
      this.compareChars.push(char);
      this.disableCompare = this.comparePossible;
      if (this.comparePossible) {
        window.requestAnimationFrame(() => {
          let btnCompare = this.$refs.btnCompare;
          btnCompare.focus();
          window.scrollTo({
            top: btnCompare.offsetTop - 30,
            behavior: "smooth",
          });
        });
      }
    },
    removeFromCompare(char) {
      var index = -1;
      for (var i = 0; i < this.compareChars.length; i++) {
        if (this.compareChars[i].id == char.id) {
          index = i;
          break;
        }
      }
      if (index >= 0) {
        this.compareChars.splice(index, 1);
      }
      this.disableCompare = this.comparePossible;
    },
    getGroupCharIds() {
      return this.chars.map(char => { return char.id });
    },
    validateResolveCutoff(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },
    updateTitle: function (message) {
      if (!message) {
        const charStr = this.chars.length > 0 ? `(${this.chars.length}) ` : "";
        message = `Group ${charStr}`;
      }
      document.title = `${message} | eq2cmp`;
    },
    updateLink: function () {
      const opts = {
        chars: this.chars.map(x => x.id),
        groupName: this.selectedGroupName ?? ""
      };
      const optsStr = btoa(JSON.stringify(opts));
      this.$emit("newLink", `${window.location.origin}${publicPath}group?opts=${optsStr}`);
    },
    doCompare() {
      var chars = [];
      for (var i = 0; i < this.compareChars.length; i++) {
        if (this.compareChars[i]) {
          chars.push(
            `char=${this.compareChars[i].server}.${this.compareChars[i].name}`
          );
        }
      }
      const link =
        chars.length < 1
          ? ""
          : `${window.location.origin}${publicPath}?${chars.join("&")}&autoload`;
      window.open(link, "_blank").focus();
    },
    saveCurrentGroup() {
      if (this.selectedGroupName) {
        addOrReplaceGroup(this.selectedGroupName, this.getGroupCharIds());
        this.loadSavedGroups();
      }
    },
    saveNewGroup() {
      if (this.newGroupName) {
        addOrReplaceGroup(this.newGroupName, this.getGroupCharIds());
        this.loadSavedGroups();
        this.selectedGroupName = this.newGroupName;
      }
    },
    loadSavedGroup() {
      if (this.selectedGroupName) {
        const group = loadGroup(this.selectedGroupName);
        if (group) {
          const playerIds = group.ids || [];
          this.loadPlayers(playerIds);
          this.newGroupName = group.name;

          this.updateTitle();
          this.updateLink();
        }
      }
    },
    removeSavedGroup() {
      if (this.selectedGroupName) {
        removeGroup(this.selectedGroupName);
        this.loadSavedGroups();

        this.updateTitle();
        this.updateLink();
      }
    },
    loadSavedGroups() {
      this.savedGroups.splice(0, this.savedGroups.length);
      this.savedGroups.push(...getGroups());
    }
  },
};
</script>

<style lang="scss" scoped>
h2 {
  margin-top: 1em;
  font-size: 1.4em;
}
.found-char {
  margin: 0.5em 0.5em 0 0.5em;
}
.accordion {
  margin-top: 1em;
  margin-bottom: 1em;
}
.accordion-button {
  background-color: #e7f1ff;
}
.accordion-body {
  background-color: #e7f1ff;
  & > div:not(:last-child) {
    margin-bottom: 0.75em;
  }
}
.option-section:not(:last-child) {
  margin-bottom: 0.75em;
}
#filterItemsByResolve {
  display: inline-block;
  max-width: 6em;
  margin-left: 0.5em;
}
#sort {
  display: inline-block;
  max-width: 20ch;
  margin-left: 0.75em;
  margin-bottom: 0.75em;
}
.resolve-checks {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em 1.5em;
}
.compare-actions {
  .form-check {
    display: inline-block;
    margin-right: 1em;
  }
  button {
    margin-top: -0.5em;
  }
}
</style>
