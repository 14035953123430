<template>
  <div class="char-selector">
    <div class="input-group">
      <select class="form-select" v-model.trim="server">
        <option value="Antonia Bayle">Antonia Bayle</option>
        <option value="Halls of Fate">Halls of Fate</option>
        <option value="Isle of Refuge">Isle of Refuge</option>
        <option value="Kaladim">Kaladim</option>
        <option value="Maj'Dul">Maj'Dul</option>
        <option value="Rivervale">Rivervale</option>
        <option value="Skyfire">Skyfire</option>
        <option value="Thurgadin">Thurgadin</option>
      </select>
      <input type="text" class="form-control" v-model.trim="charName" placeholder="Character" @keypress.enter="fetchStats">
      <input id="search-button" type="button" class="btn btn-primary" :value="getButtonText" @click="fetchStats" :disabled="getButtonDisabled">
    </div>
  </div>
</template>

<script>
import { getPlayerStatsByNameAsync } from '@/util/eqApi';

export default {
  name: 'CharSelector',
  props: {
    serverHint: String,
    charHint: String,
    autoload: Boolean
  },
  data: function() {
    return {
      server: "",
      charName: "",
      char: null,
      isFetching: false
    }
  },
  computed: {
    getButtonText() {
      return this.isFetching ? "Searching..." : "Search";
    },
    getButtonDisabled() {
      return this.isFetching;
    },
  },
  methods: {
    fetchStats: async function() {
      if (this.server != null && this.server.trim() !== "" &&
          this.charName != null && this.charName.trim() !== "") {
        this.$emit('apiSearch');

        this.server = this.server.charAt(0).toUpperCase() + this.server.slice(1);
        this.charName = this.charName.charAt(0).toUpperCase() + this.charName.slice(1).toLowerCase();
        this.isFetching = true;
        try {
          this.char = await getPlayerStatsByNameAsync(this.server, this.charName);
          if (this.char == null) {
            throw "Char stats not found";
          }
          this.$emit('charFound', { char: this.char, charName: this.charName, server: this.server });
        }
        catch (err) {
          if (err === 'service_unavailable') { // Thrown by the api layer
            this.$emit('apiError');
          }
          else {
            this.$emit('charFound', { char: null, charName: this.charName, server: this.server });
          }
        }
        this.isFetching = false;
      }
    },
  },
  mounted: function() {
    if (this.serverHint) {
      this.server = this.serverHint;
    }
    if (this.charHint) {
      this.charName = this.charHint;
    }
    if (this.autoload && this.server != "" && this.charName != "") {
      this.fetchStats();
    }
  }
}
</script>

<style lang="scss" scoped>
.char-selector {
  margin: 0;
  padding: 0.5em 0.5em 0.5em 0.5em;
  background-color: #f8f8f8;
  border-radius: 4px;
}
#search-button {
  width: 8em;
}
</style>
